import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Bio from '../components/bio'
import { rhythm, scale } from '../utils/typography'

const IndexPage = ({ data }) => (
    <Layout>
        <SEO title="Blog" keywords={['blog', 'rohit mundra']} />
        <h1 style={{fontSize: 38}}>
            Rohit Mundra
        </h1>
        <Bio />
        <ul>
        {data.allMarkdownRemark.edges.map(post => (
            <li key= { post.node.id }>
                <Link to={post.node.frontmatter.path}>
                    <span style={{ fontSize: "1.4rem" }}>
                        { post.node.frontmatter.title }
                    </span>
                </Link>
            </li>
        ))}
        </ul>
    </Layout>
)

export const pageQuery = graphql`
    query IndexQuery {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    id
                    frontmatter {
                        path
                        title
                        author
                        date
                    }
                }
            }
        }
    }
`

export default IndexPage

//---------

// import React from 'react'
// import { Link } from 'gatsby'

// import Layout from '../components/layout'
// import Image from '../components/image'
// import SEO from '../components/seo'

// const IndexPage = () => (
//   <Layout>
//     <SEO title="Home" keywords={['gatsby', 'application', 'react']} />
//     <h1>Hi people</h1>
//     <p>Welcome to your new Gatsby site.</p>
//     <p>Now go build something great.</p>
//     <div style={{ maxWidth: '300px', marginBottom: '1.45rem' }}>
//       <Image />
//     </div>
//     <Link to="/page-2/">Go to page 2</Link>
//   </Layout>
// )

// export default IndexPage

